<template>
	<el-tabs v-model="activeName" style='background: white; padding-top: 20px;box-shadow: 0px 0px 6px #c4c4c4;' :stretch='true'
	 @tab-click='Switch_tab(this)'>
		<el-row>
			<el-col :span='8' :push='1' style='margin-bottom: 5px;'>
				<el-input placeholder="请输入内容" v-model="input_search" class="input-with-select">
					<el-button slot="append" icon="el-icon-search" @click="btn_click_search()"></el-button>
				</el-input>
			</el-col>
		</el-row>

		<!-- 已上架列表 -->
		<el-tab-pane label="审核通过/已上架" name="1">
			<el-table :data="Approved" style="width: 100%">
				<el-table-column prop="id" label="ID" align=center>
				</el-table-column>
				<el-table-column prop="author" label="作者" align=center>
				</el-table-column>
				<el-table-column prop="name" label="资源图片" align=center>
					<template slot-scope='scope'>
						<el-row>
							<el-col>
								<img :src="common.retun_img(scope.row.thumbnail)" oncontextmenu="return false;" alt="图片加载失败" style="width: 100px;height: 100px; display: block; margin: 0 auto;">
							</el-col>
						</el-row>
					</template>
				</el-table-column>
				<el-table-column prop="name" align=center label="名称">
				</el-table-column>
				<el-table-column prop="create_time" align=center label="分享时间">
				</el-table-column>
				<el-table-column prop="goldcoin" align=center label="定价金币">
				</el-table-column>
				<el-table-column align=center label="操作" width="380">
					<template slot-scope='scope'>
						<el-row>
							<el-col>
								<el-button @click='Revisepricing(scope.row)' size='mini'>
									修改定价
								</el-button>
								<el-button @click="OffTheShel(scope.row)" size='mini'>
									下架
								</el-button>
								<el-button @click="bin_details(scope.row)" size='mini' v-show="scope.row.resource_type!=1">
									关联
								</el-button>
								<el-button @click='ReLaunch(scope.row)' size='mini'>修改</el-button>
								<el-button @click='RejectDeletion(scope.row)' size='mini'>删除</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size='pagesize'
			 :total='allpage' style='margin-left: calc(40%);margin-top: 20px;' v-if="allpage>=9">
			</el-pagination>
		</el-tab-pane>
		<!-- 未上架 -->
		<el-tab-pane label="未上架" name="6">
			<el-table :data="NotonSale" style="width: 100%">
				<el-table-column prop="id" label="ID" align=center>
				</el-table-column>
				<el-table-column prop="author" label="作者" align=center>
				</el-table-column>
				<el-table-column prop="name" label="资源图片" align=center>
					<template slot-scope='scope'>
						<el-row>
							<el-col>
								<img :src="common.retun_img(scope.row.thumbnail)" oncontextmenu="return false;" alt="图片加载失败" style="width: 100px;height: 100px; display: block; margin: 0 auto;">
							</el-col>
						</el-row>
					</template>
				</el-table-column>
				<el-table-column prop="name" align=center label="名称">
				</el-table-column>
				<el-table-column prop="create_time" align=center label="分享时间">
				</el-table-column>
				<el-table-column prop="goldcoin" align=center label="定价金币">
				</el-table-column>
				<el-table-column align=center label="操作" width="380">
					<template slot-scope='scope'>
						<el-row>
							<el-col>
								<el-button size='mini' @click='shangjia(scope.row)'>上架</el-button>
								<el-button @click='ReLaunch(scope.row)' size='mini'>修改</el-button>
								<el-button @click='RejectDeletion(scope.row)' size='mini'>删除</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size='pagesize'
			 :total='allpage' style='margin-left: calc(40%);margin-top: 20px;' v-if="allpage>=9">
			</el-pagination>
		</el-tab-pane>
		<!-- 待审核列表 -->
		<el-tab-pane label="待审核" name="0">
			<el-table :data="To_be_reviewed" style="width: 100%" ref='multipleTable'>
				<el-table-column prop="id" label="ID" align=center>
				</el-table-column>
				<el-table-column prop="author" label="作者" align=center>
				</el-table-column>
				<el-table-column prop="name" label="资源图片" align=center>
					<template slot-scope='scope'>
						<el-row>
							<el-col>
								<img :src="common.retun_img(scope.row.thumbnail)" oncontextmenu="return false;" alt="图片加载失败" style="width: 100px;height: 100px; display: block; margin: 0 auto;">
							</el-col>
						</el-row>
					</template>
				</el-table-column>
				<el-table-column prop="name" align=center label="名称">
				</el-table-column>
				<el-table-column prop="create_time" align=center label="分享时间">
				</el-table-column>
				<el-table-column prop="" align=center label="操作" v-if='!username'>
					<template slot-scope='scope'>
						<el-row>
							<el-col>
								<el-button @click='adopt(scope.row)'>通过</el-button>
								<el-button @click='reject(scope.row)'>驳回</el-button>
								<el-button @click='reject(scope.row)'>下载资源</el-button>

							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size='pagesize'
			 :total='allpage' style='margin-left: calc(40%);margin-top: 20px;' v-if="allpage>=9">
			</el-pagination>
		</el-tab-pane>
		<!-- 驳回列表 -->
		<el-tab-pane label="驳回" name="3">
			<el-table :data="Rejection" style="width: 100%">
				<el-table-column prop="id" label="ID" align=center>
				</el-table-column>
				<el-table-column prop="author" label="作者" align=center>
				</el-table-column>
				<el-table-column prop="name" label="资源图片" align=center>
					<template slot-scope='scope'>
						<el-row>
							<el-col>
								<img :src="common.retun_img(scope.row.thumbnail)" oncontextmenu="return false;" alt="图片加载失败" style="width: 100px;height: 100px; display: block; margin: 0 auto;">
							</el-col>
						</el-row>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="名称" align=center>
				</el-table-column>
				<el-table-column prop="create_time" align=center label="分享时间">
				</el-table-column>
				<el-table-column prop="look_number" align=center label="驳回理由">
					<template slot-scope='scope'>
						<el-row>
							<el-col>
								<el-button @click='seereason(scope.row)'>查看</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
				<el-table-column prop="look_number" align=center label="操作" width="160">
					<template slot-scope='scope'>
						<el-row>
							<el-col>
								<el-button @click='ReLaunch(scope.row)' size='mini'>修改</el-button>
								<el-button @click='RejectDeletion(scope.row)' size='mini'>删除</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>
		</el-tab-pane>
		<!-- 已下架资源 -->
		<el-tab-pane label="已下架资源" name="2">
			<el-table :data="Off_the_shelf" style="width: 100%">
				<el-table-column prop="id" label="ID" align=center>
				</el-table-column>
				<el-table-column prop="author" label="作者" align=center>
				</el-table-column>
				<el-table-column prop="name" label="资源图片" align=center>
					<template slot-scope='scope'>
						<el-row>
							<el-col>
								<img :src="common.retun_img(scope.row.thumbnail)" oncontextmenu="return false;" alt="图片加载失败" style="width: 100px;height: 100px; display: block; margin: 0 auto;">
							</el-col>
						</el-row>
					</template>
				</el-table-column>
				<el-table-column prop="name" align=center label="名称">
				</el-table-column>
				<el-table-column prop="create_time" align=center label="分享时间">
				</el-table-column>
				<el-table-column prop="look_number" align=center label="浏览量">
				</el-table-column>
				<el-table-column prop="download_number" align=center label="下载量">
				</el-table-column>
				<el-table-column prop="goldcoin" align=center label="定价金币">
				</el-table-column>
				<el-table-column prop="message" align=center label="下架理由">
				</el-table-column>
				<el-table-column prop="goldcoin" align=center label="操作" width="180">
					<template slot-scope='scope'>
						<el-row>
							<el-col>
								<el-button @click='RejectDeletion(scope.row)' size='mini'>删除</el-button>
								<el-button @click='ReLaunch(scope.row)' size='mini'>修改</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size='pagesize'
			 :total='allpage' style='margin-left: calc(40%);margin-top: 20px;' v-show="allpage>=9">
			</el-pagination>
		</el-tab-pane>
		<!-- 全部记录列表 -->
		<el-tab-pane label="全部记录" name="5" ref='All_records' style="width: 1100px;">
			<el-table :data="All_records" style="width: 1100px;background: white; ">
				<!--create_time: "2020-07-28 10:27:55"
						download_number: 0
						goldcoin: 100
						id: 15407
						look_number: 0
						name: "测试(1)"
						state: "已上架"
						thumbnail: "C2110C1720FAEAE6E9729869FACB41DB.png" -->
				<!-- ID列表 -->
				<el-table-column prop="id" label="ID" align=center>
				</el-table-column>
				<!-- 作者列表 -->
				<el-table-column prop="author" label="作者" align=center>
				</el-table-column>
				<!-- 资源图片列表 -->
				<el-table-column prop="thumbnail" label="资源图片" align=center>
					<template slot-scope='scope'>
						<el-row>
							<el-col>
								<img :src="common.retun_img(scope.row.thumbnail)" oncontextmenu="return false;" alt="图片加载失败" style="width: 100px;height: 100px; display: block; margin: 0 auto;">
							</el-col>
						</el-row>
					</template>
				</el-table-column>
				<!-- 名称列表 -->
				<el-table-column prop="name" align=center label="名称">
				</el-table-column>
				<!-- 分享时间列表 -->
				<el-table-column prop="create_time" align=center label="分享时间">
				</el-table-column>
				<!-- 状态列表 -->
				<el-table-column prop="state" align=center label="状态">
				</el-table-column>
				<!-- 浏览量列表 -->
				<el-table-column prop="look_number" align=center label="浏览量">
				</el-table-column>
				<!-- 下载量列表 -->
				<el-table-column prop="download_number" align=center label="下载量">
				</el-table-column>
				<!-- 定价金币列表 -->
				<el-table-column prop="goldcoin" align=center label="定价金币">
				</el-table-column>
				<el-table-column prop="goldcoin" align=center label="操作" width="150">
					<template slot-scope='scope'>
						<el-row>
							<el-col>
								<el-button @click='ReLaunch(scope.row)' size='mini'>修改</el-button>
								<el-button @click='RejectDeletion(scope.row)' size='mini'>删除</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size='pagesize'
			 :total='allpage' style='margin-left: calc(40%);margin-top: 20px;' v-if="allpage>=9">
			</el-pagination>
		</el-tab-pane>
		<el-dialog title="绑定" :visible.sync="dialogVisibleoff_bind" width="30%">


			<el-select v-model="bind_value" placeholder="请选择">
				<el-option v-for="item in bind_options" :key="item.id" :label="item.name" :value="item.id">
				</el-option>
			</el-select>



			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisibleoff_bind = false">取 消</el-button>
				<el-button type="primary" @click="bind_determine()">确定</el-button>
			</span>
		</el-dialog>
		<!-- 点击下架弹出 -->
		<el-dialog title="下架理由" :visible.sync="dialogVisibleoff_the_shelf" width="30%">
			<el-input type="textarea" v-model="off_the_shelf_text"></el-input>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisibleoff_the_shelf = false">取 消</el-button>
				<el-button type="primary" @click="off_the_shelf_determine()">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 点击修改定价弹出 -->
		<el-dialog title="修改定价" :visible.sync="dialogVisiblese" width="30%">
			<el-form ref="form" :model="form" label-width="110px">
				<el-form-item label="修改资源定价">
					<el-input v-model="form.name"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisiblese = false">取 消</el-button>
				<el-button type="primary" @click="Revisepricing_determine()">确 定</el-button>
			</span>
		</el-dialog>
	</el-tabs>

</template>
<script>
	import {
		//上传记录资源
		requestaccount_account_share_list,
		//修改定价
		resource_goldcoin_modify,
		//删除已下架资源
		resource_delete,
		//下架
		resource_state_lower,
		account_resource_bind,
		account_resource_public
	} from '../../api/request.js'
	export default {
		components: {},
		data() {
			return {
				activeName: '1',
				//全部记录
				All_records: [],
				//审核通过
				Approved: [],
				//待审核
				To_be_reviewed: [],
				//驳回
				Rejection: [],
				//已下架
				Off_the_shelf: [],
				NotonSale: [],
				//判断是否为超级管理员
				username: true,
				dialogVisible: false,
				desc: null,
				//修改定价
				dialogVisiblese: false,
				//表单
				form: {
					name: ''
				},
				//驳回定价资源详情
				Reject_pricing: '',
				//分页显示条数
				pagesize: null,
				//所以资源条数
				allpage: null,
				//分页显示当前页面
				current_page: 1,
				//下架理由弹出框
				dialogVisibleoff_the_shelf: false,
				//下架理由
				off_the_shelf_text: null,
				//需要下架的资源
				off_the_shelf_item: null,
				//搜索框绑定值
				input_search: null,
				options: [{
					value: 'ID',
					label: 'id'
				}, {
					value: 'name',
					label: '名称'
				}],
				value: 'id',
				is_animation: '',
				bind_data: null,
				dialogVisibleoff_bind: false,
				bind_options: [],
				bind_value: ''
			}
		},
		methods: {
			//删除资源
			RejectDeletion(item) {
				this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						resource_id: item.id
					}
					resource_delete(data).then(res => {
						let data = {
							state: parseInt(this.activeName),
							is_animation: parseInt(this.is_animation)
						};
						requestaccount_account_share_list(data).then(res => {
							if (this.activeName == '2') {
								this.Off_the_shelf = res.ret.data;
								let listData = res.ret;
								this.MyShow.Off_the_shelf = listData;
							} else if (this.activeName == '3') {
								this.Rejection = res.ret.data;
								let listData = res.ret;
								this.MyShow.Rejection = listData;
							}
							this.statelist(res);
						})
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			//搜索
			btn_click_search() {
				let data;
				data = {
					state: parseInt(this.activeName),
					search: this.input_search,
					is_animation: parseInt(this.is_animation)
				};
				this.obtain_data(data)
			},
			shangjia(item) {
				let data = {
					resource_id: item.id,
					is_public: 1
				}
				account_resource_public(data).then(res => {
					if (res.code == 0) {
						this.$message({
							message: '已成功申请上架，等待管理员审核',
							type: 'success',
							offset:150
						})
						let data = {
							state: 6,
							is_animation: parseInt(this.is_animation)
							//全部
						}
						requestaccount_account_share_list(data).then(res => {
							this.NotonSale = res.ret.data;
							this.pagesize = res.ret.per_page;
							this.allpage = res.ret.total;
							this.statelist(res)
						})
					}
				})
			},
			obtain_data(data) {
				requestaccount_account_share_list(data).then(res => {
					this.All_records = res.ret.data;
					if (this.activeName == '5') {
						this.All_records = res.ret.data;
						let listData = res.ret
						this.MyShow.All_records = listData;
						this.MyShow.All_records_length = res.ret.data.length;
					} else if (this.activeName == '1') {
						this.Approved = res.ret.data;
						let listData = res.ret;
						this.MyShow.Approved = listData;
					} else if (this.activeName == '2') {
						this.Off_the_shelf = res.ret.data;
						let listData = res.ret;
						this.MyShow.Off_the_shelf = listData;
					} else if (this.activeName == '3') {
						this.Rejection = res.ret.data;
						let listData = res.ret;
						this.MyShow.Rejection = listData;
					} else if (this.activeName == '0') {
						this.To_be_reviewed = res.ret.data;
						let listData = res.ret;
						this.MyShow.To_be_reviewed = listData;
						this.MyShow.To_be_reviewed_length = res.ret.data.length
					} else if (this.activeName == '6') {
						this.NotonSale = res.ret.data;
						let listData = res.ret;
						this.MyShow.NotonSale = listData;
						this.MyShow.NotonSale_length = res.ret.data.length
					}
					this.statelist(res);
				})
			},


			bin_details(item) {

				let is_animation,
					_this = this;;

				if (item.resource_type == 3) {
					is_animation = 2
				} else if (item.resource_type == 2) {
					is_animation = 3
				}

				let data = {
					state: 1,
					is_animation: is_animation
				}
				requestaccount_account_share_list(data).then(res => {
					console.log(res)

					_this.bind_options = res.ret.data
				})
				this.dialogVisibleoff_bind = true;
				this.bind_data = item;
			},
			bind_determine() {
				let data = {
					resource_id: this.bind_value,
					bind_resource_id: this.bind_data.id,
				}
				account_resource_bind(data).then(res => {
					if (res.code == 0) {
						this.dialogVisibleoff_bind = false;
						this.$message({
							message: "绑定成功",
							type: "success",
							offset: 100
						});

					}

					console.log(res)
				})
			},

			//分页处理函数
			handleCurrentChange(val) {
				window.scrollTo(0, 0);
				let data = {
					state: parseInt(this.activeName),
					is_animation: parseInt(this.is_animation)
				};
				requestaccount_account_share_list(data, val).then(res => {
					this.All_records = res.ret.data;
					if (this.activeName == '5') {
						this.All_records = res.ret.data;
						let listData = res.ret
						this.MyShow.All_records = listData;
						this.MyShow.All_records_length = res.ret.data.length;
					} else if (this.activeName == '1') {
						this.Approved = res.ret.data;
						let listData = res.ret;
						this.MyShow.Approved = listData;
					} else if (this.activeName == '2') {
						this.Off_the_shelf = res.ret.data;
						let listData = res.ret;
						this.MyShow.Off_the_shelf = listData;
					} else if (this.activeName == '3') {
						this.Rejection = res.ret.data;
						let listData = res.ret;
						this.MyShow.Rejection = listData;
					} else if (this.activeName == '0') {
						this.To_be_reviewed = res.ret.data;
						let listData = res.ret;
						this.MyShow.To_be_reviewed = listData;
						this.MyShow.To_be_reviewed_length = res.ret.data.length
					} else if (this.activeName == '6') {
						this.NotonSale = res.ret.data;
						let listData = res.ret;
						this.MyShow.NotonSale = listData;
						this.MyShow.NotonSale_length = res.ret.data.length
					}
					this.statelist(res);
				})
			},
			//下架处理函数
			OffTheShel(item) {
				this.off_the_shelf_item = item;
				this.dialogVisibleoff_the_shelf = true;
			},
			//下架理由处理函数
			off_the_shelf_determine() {
				let data = {
					resource_id: this.off_the_shelf_item.id,
					message_content: this.off_the_shelf_text
				};
				// data={"token":"","account_id":(int)资源所属的用户accoun_id,"resource_id":资源id(int),"resource_name":"资源名称","message_content":"下架理由"}
				resource_state_lower(data).then((res) => {
					if (res.code == 0) {
						this.$message({
							message: "下架成功",
							type: "success",
						});
						this.dialogVisibleoff_the_shelf = false;

						let data = {
							state: 1,
							//全部
							is_animation: parseInt(this.is_animation)
						};
						requestaccount_account_share_list(data).then(res => {
							this.Approved = res.ret.data;
							this.statelist(res);
						})
					}
				});
			},
			//查看驳回理由
			seereason(item) {
				this.$alert(item.message, '标题名称', {
					confirmButtonText: '确定',
					callback: action => {}
				});
			},
			//驳回 修改资源源文件 直接跳转到上传页面
			ReLaunch(item) {
				console.log(item)
				let parent = this.$parent.$parent.$parent.$parent;
				parent.$refs.ModeUpload.modifyID = item.id;
				parent.$refs.MyShowLeft.activeNames = 0;
				parent.set_current_select_sequence(4);
				// parent.$refs.MyShowLeft.initupload();
			},
			//获取数据
			Switch_tab: function(_this) {
				if (this.MyShow.All_records.length != 0 && this.activeName == '5') {
					this.All_records = this.MyShow.All_records.data;
					this.pagesize = this.MyShow.All_records.per_page;
					this.allpage = this.MyShow.All_records.total;
					return
				} else if (this.MyShow.Approved.length != 0 && this.activeName == '1') {
					this.Approved = this.MyShow.Approved.data;
					this.pagesize = this.MyShow.Approved.per_page;
					this.allpage = this.MyShow.Approved.total;
					return
				} else if (this.MyShow.Off_the_shelf.length != 0 && this.activeName == '2') {
					this.Off_the_shelf = this.MyShow.Off_the_shelf.data;
					this.pagesize = this.MyShow.Off_the_shelf.per_page;
					this.allpage = this.MyShow.Off_the_shelf.total;
					return
				} else if (this.MyShow.Rejection.length != 0 && this.activeName == '3') {
					this.Rejection = this.MyShow.Rejection.data;
					this.pagesize = this.MyShow.Rejection.per_page;
					this.allpage = this.MyShow.Rejection.total;
					return
				} else if (this.MyShow.To_be_reviewed.length != 0 && this.activeName == '0' && this.MyShow.To_be_reviewed_length ==
					this.MyShow.To_be_reviewed.length) {
					this.To_be_reviewed = this.MyShow.To_be_reviewed.data;
					this.pagesize = this.MyShow.To_be_reviewed.per_page;
					this.allpage = this.MyShow.To_be_reviewed.total;
					return
				} else if (this.MyShow.NotonSale.length != 0 && this.activeName == '0' && this.MyShow.NotonSale_length ==
					this.MyShow.NotonSale.length) {
					this.NotonSale = this.MyShow.NotonSale.data;
					this.pagesize = this.MyShow.NotonSale.per_page;
					this.allpage = this.MyShow.NotonSale.total;
					return
				};
				let data = {
					state: parseInt(this.activeName),
					is_animation: parseInt(this.is_animation)
					//全部
				};
				requestaccount_account_share_list(data).then(res => {
					this.All_records = res.ret.data;
					if (this.activeName == '5') {
						this.All_records = res.ret.data;
						let listData = res.ret
						this.MyShow.All_records = listData;
						this.MyShow.All_records_length = res.ret.data.length;
						this.pagesize = res.ret.per_page;
						this.allpage = res.ret.total;
					} else if (this.activeName == '1') {
						this.Approved = res.ret.data;
						let listData = res.ret;
						this.MyShow.Approved = listData;
						this.pagesize = res.ret.per_page;
						this.allpage = res.ret.total;
					} else if (this.activeName == '2') {
						this.Off_the_shelf = res.ret.data;
						let listData = res.ret
						this.MyShow.Off_the_shelf = listData
						this.pagesize = res.ret.per_page;
						this.allpage = res.ret.total;
					} else if (this.activeName == '3') {
						this.Rejection = res.ret.data;
						let listData = res.ret;
						this.MyShow.Rejection = listData;
						this.pagesize = res.ret.per_page;
						this.allpage = res.ret.total;
					} else if (this.activeName == '0') {
						this.To_be_reviewed = res.ret.data;
						let listData = res.ret;
						this.MyShow.To_be_reviewed = listData;
						this.MyShow.To_be_reviewed_length = res.ret.data.length;
						this.pagesize = res.ret.per_page;
						this.allpage = res.ret.total;
					} else if (this.activeName == '6') {
						this.NotonSale = res.ret.data;
						let listData = res.ret;
						this.MyShow.NotonSale = listData;
						this.MyShow.NotonSale_length = res.ret.data.length;
						this.pagesize = res.ret.per_page;
						this.allpage = res.ret.total;
					}
					this.statelist(res);
				})
			},
			//修改定价
			Revisepricing(item) {
				this.form.name = item.goldcoin;
				this.dialogVisiblese = true;
				this.RevisepricingData = item;
			},
			//修改定价确定
			Revisepricing_determine() {
				if (this.form.name == '') {
					this.$message({
						message: '价格不能为空',
						type: 'warning'
					});
					return
				}
				this.dialogVisiblese = false;
				let data = {
					resource_id: this.RevisepricingData.id,
					goldcoin: parseInt(this.form.name),
				}
				resource_goldcoin_modify(data).then(res => {})
			},
			//状态转换
			statelist(res) {
				for (let i = 0; i < res.ret.data.length; i++) {
					if (res.ret.data[i].state == 2) {
						res.ret.data[i].state = '未上架'
					} else if (res.ret.data[i].state == 3) {
						res.ret.data[i].state = '驳回'
					} else if (res.ret.data[i].state == 1) {
						res.ret.data[i].state = '已上架'
					} else if (res.ret.data[i].state == 0) {
						res.ret.data[i].state = '待审核'
					} else if (res.ret.data[i].state == 4) {
						res.ret.data[i].state = '已删除'
					}
				}
			},
			init() {

				let data = {
					state: 1,
					is_animation: parseInt(this.is_animation)
					//全部
				}
				requestaccount_account_share_list(data).then(res => {
					this.activeName = '1';
					this.Approved = res.ret.data;
					this.pagesize = res.ret.per_page;
					this.allpage = res.ret.total;
					this.statelist(res)
				})
			}
		},
		mounted() {
			let _this = this;
			//获取登录的ID
			let userId = this.Account.GetStorage();
			if (userId.account_id == 2156) {
				this.username = false
			} else {
				this.username = true
			}

			this.init()

		}
	};
</script>
<style scoped>
	@import "../../style/common.css";

	>>>.el-tabs__item.is-active {
		color: rgb(203, 80, 49)
	}

	>>>.el-tabs__active-bar {
		background-color: rgb(203, 80, 49)
	}

	>>>.el-tabs__item:hover {
		color: rgb(203, 80, 49)
	}

	>>>.el-input__inner:focus {
		border-color: rgb(203, 80, 49);
	}

	>>>.el-button:active {

		color: rgb(203, 80, 49);
		border-color: rgb(203, 80, 49);
		background-color: white;
	}

	>>>.el-button:hover {
		background-color: white;
		color: rgb(203, 80, 49);
		border-color: rgb(203, 80, 49);
	}
</style>
